import React, { ReactElement } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { sourceOfFundsAndWealth } from '../../../../../../data/sourceOfFundsAndWealth'
import VerificationStepEntity from '../../../../../../dto/VerificationStepEntity.interface'
// import { InvestmentAmount } from '../../../../../../enums/InvestmentAmount'
import DropdownSelected from '../../../../../inputs/Dropdowns/DropdownSelected'
import TextInput from '../../../../../inputs/TextInput'
import InputOptionType from '../../../../../../enums/InputOptionType'
import TextArea from '../../../../../inputs/TextArea'

interface Props {
  disabled?: boolean
  methods: UseFormReturn<VerificationStepEntity, any>
}

const SourceOfFundsAndWealth: React.FC<Props> = ({
  methods,
  disabled
}: Props): ReactElement => {
  const sourceOfWealth = methods.watch('questionnaire.sourceOfWealth') as {
    value: string
    label: string
  }

  const sourceOfFunds = methods.watch('questionnaire.sourceOfFunds') as {
    value: string
    label: string
  }

  // const intentedIvestmentAmount = methods.getValues(
  //   'questionnaire.intendedInvestmentAmount'
  // ) as any

  // const showSourceOfFundsDescription = (): boolean => {
  //   switch (intentedIvestmentAmount.value) {
  //     case InvestmentAmount.TILL100000:
  //     case InvestmentAmount.OVER100000:
  //       return true
  //     default:
  //       return false
  //   }
  // }

  // const showSourceOfWealthDescription = (): boolean => {
  //   switch (intentedIvestmentAmount.value) {
  //     case InvestmentAmount.TILL100000:
  //     case InvestmentAmount.OVER100000:
  //       return true
  //     default:
  //       return false
  //   }
  // }

  return (
    <>
      <DropdownSelected
        variable="questionnaire.sourceOfFunds"
        options={sourceOfFundsAndWealth}
        title="Source of Funds"
        required={true}
        disabled={disabled}
      />
      {sourceOfFunds && sourceOfFunds.value === 'Other (please describe)' && (
        <TextInput
          variable="questionnaire.sourceOfFundsOther"
          title="Other source of Funds"
          optionType={InputOptionType.TEXT}
          className="w-full"
          disabled={disabled}
        />
      )}
      <TextArea
        disabled={disabled}
        variable="questionnaire.sourceOfFundsDescription"
        title="Detailed Source of Funds description"
        optionType={InputOptionType.TEXT}
        className="w-full"
      />
      <DropdownSelected
        variable="questionnaire.sourceOfWealth"
        options={sourceOfFundsAndWealth}
        title="Source of Wealth"
        required={true}
        disabled={disabled}
      />
      {sourceOfWealth && sourceOfWealth.value === 'Other (please describe)' && (
        <TextInput
          variable="questionnaire.sourceOfWealthOther"
          title="Other source of Wealth"
          optionType={InputOptionType.TEXT}
          className="w-full"
          disabled={disabled}
        />
      )}
      <TextArea
        disabled={disabled}
        variable="questionnaire.sourceOfWealthDescription"
        title="Detailed Source of Wealth description"
        optionType={InputOptionType.TEXT}
        className="w-full"
      />
    </>
  )
}

export default SourceOfFundsAndWealth
