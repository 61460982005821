/* eslint-disable multiline-ternary */
import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { annualIncome } from '../../../../data/annualIncome'
import { branches } from '../../../../data/branches'
import { intendedUseOfAssets } from '../../../../data/intendedUseOfAssets'
import { investmentAmounts } from '../../../../data/investmentAmounts'
import { occupation } from '../../../../data/occupation'
import { sourceOfFundsAndWealth } from '../../../../data/sourceOfFundsAndWealth'
import { totalWealth } from '../../../../data/totalWealth'
import VerificationStepEntity from '../../../../dto/VerificationStepEntity.interface'
// import { InvestmentAmount } from '../../../../enums/InvestmentAmount'
import { VerificationStepStatus } from '../../../../enums/VerificationStepStatus'
import userStore from '../../../../stores/user.store'
import { VerificationStore } from '../../../../stores/verificaton.store'
import { findElementInArray } from '../../../../util/DropDownHelper'
import { onPromise } from '../../../../util/formHelper'
import { checkRedoNavigate } from '../../../../util/navigateHelper'
import CardScrollable from '../../../CardScrollable/CardScrollable'
import { ButtonProps } from '../../../button/ButtonRow'
import InvestmentQuestions from './financialInformationSubpages/InvestmentQuestions'
import { FlowType } from '../../../../dto/User.interface'
import KycFinancialInformation from './financialInformationSubpages/KycFinancialInformation'
import KybFinancialInformation from './financialInformationSubpages/KybFinancialInformation'

interface Props {
  verificationStore: VerificationStore
}

const FinancialInformation = ({ verificationStore }: Props): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState(0)

  const methods: UseFormReturn<VerificationStepEntity, any> =
    useForm<VerificationStepEntity>({ mode: 'onChange' })
  const navigate = useNavigate()

  useEffect(() => {
    const load = async (): Promise<void> => {
      setIsLoading(true)
      await userStore.getMe().catch((err) => console.log(err))
      await verificationStore.loadQuestionnaireInformation().catch((err) => {
        console.log(err)
      })
      setIsLoading(false)
    }
    load().catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (verificationStore.questionnaire) {
      const questionnaire = verificationStore.questionnaire
      methods.setValue('questionnaire', verificationStore.questionnaire)
      if (questionnaire.intendedInvestmentAmount) {
        methods.setValue(
          'questionnaire.intendedInvestmentAmount',
          findElementInArray(
            investmentAmounts as any,
            questionnaire.intendedInvestmentAmount as any
          )
        )
      }

      if (questionnaire.intendedUseOfAssets) {
        methods.setValue(
          'questionnaire.intendedUseOfAssets',
          findElementInArray(
            intendedUseOfAssets as any,
            questionnaire.intendedUseOfAssets as any
          )
        )
      }

      if (questionnaire.annualIncome) {
        methods.setValue(
          'questionnaire.annualIncome',
          findElementInArray(
            annualIncome as any,
            questionnaire.annualIncome as any
          )
        )
      }

      if (questionnaire.totalWealth) {
        methods.setValue(
          'questionnaire.totalWealth',
          findElementInArray(
            totalWealth as any,
            questionnaire.totalWealth as any
          )
        )
      }

      if (questionnaire.sourceOfFunds) {
        methods.setValue(
          'questionnaire.sourceOfFunds',
          findElementInArray(
            sourceOfFundsAndWealth as any,
            questionnaire.sourceOfFunds as any
          )
        )
      }
      if (questionnaire.sourceOfWealth) {
        methods.setValue(
          'questionnaire.sourceOfWealth',
          findElementInArray(
            sourceOfFundsAndWealth as any,
            questionnaire.sourceOfWealth as any
          )
        )
      }
      if (questionnaire.branche) {
        methods.setValue(
          'questionnaire.branche',
          findElementInArray(branches as any, questionnaire.branche as any)
        )
      }

      if (questionnaire.occupation) {
        const found = findElementInArray(
          occupation as any,
          questionnaire.occupation as any
        )
        if (found) {
          methods.setValue('questionnaire.occupation', found)
        }
        // methods.setValue('questionnaire.occupation', findElementInArray(options, questionnaire.occupation as any))
      }
    }
  }, [verificationStore.questionnaire])

  const onSubmit: SubmitHandler<VerificationStepEntity> = async (data) => {
    if (currentStep === 0) {
      setCurrentStep(1)
      return
    }
    try {
      const questionnaire = data.questionnaire

      if (!questionnaire) {
        return
      }

      setIsLoading(true)
      if (
        questionnaire.sourceOfFunds &&
        typeof questionnaire.sourceOfFunds === 'object'
      ) {
        questionnaire.sourceOfFunds = (
          questionnaire.sourceOfFunds as {
            label: string
            value: string
          }
        ).value
      }

      if (
        questionnaire.annualIncome &&
        typeof questionnaire.annualIncome === 'object'
      ) {
        questionnaire.annualIncome = (
          questionnaire.annualIncome as {
            label: string
            value: string
          }
        ).value
      }

      if (
        questionnaire.totalWealth &&
        typeof questionnaire.totalWealth === 'object'
      ) {
        questionnaire.totalWealth = (
          questionnaire.totalWealth as {
            label: string
            value: string
          }
        ).value
      }

      if (
        questionnaire.sourceOfWealth &&
        typeof questionnaire.sourceOfWealth === 'object'
      ) {
        questionnaire.sourceOfWealth = (
          questionnaire.sourceOfWealth as {
            label: string
            value: string
          }
        ).value
      }

      if (questionnaire.branche && typeof questionnaire.branche === 'object') {
        questionnaire.branche = (
          questionnaire.branche as {
            label: string
            value: string
          }
        ).value
      }

      if (
        questionnaire.intendedUseOfAssets &&
        typeof questionnaire.intendedUseOfAssets === 'object'
      ) {
        questionnaire.intendedUseOfAssets = (
          questionnaire.intendedUseOfAssets as {
            label: string
            value: string
          }
        ).value
      }

      if (questionnaire.occupation) {
        questionnaire.occupation = (
          questionnaire.occupation as {
            label: string
            value: string
          }
        ).value
      }

      questionnaire.intendedInvestmentAmount = investmentAmounts[0].value
      // if (questionnaire.intendedInvestmentAmount) {
      //   questionnaire.intendedInvestmentAmount = (
      //     questionnaire.intendedInvestmentAmount as {
      //       label: string
      //       value: string
      //     }
      //   ).value
      // }

      await verificationStore.saveQuestionnaireInformation(data)
      setIsLoading(false)

      navigate(
        `${
          verificationStore.isDirector ? '/officer-verification' : ''
        }/fund-source-evidence`
      )
    } catch (error) {
      console.error(error)
    }
  }

  const navigateBack = (): void => {
    if (currentStep === 1) {
      setCurrentStep(0)
      return
    }
    checkRedoNavigate(navigate, -1)
  }

  const isAlreadySent = (): boolean => {
    const currentUser = userStore.user
    if (
      currentUser?.status &&
      (currentUser.status === VerificationStepStatus.REJECTED ||
        currentUser.status === VerificationStepStatus.COMPLETED)
    ) {
      return true
    }

    return false
  }

  const isSent = isAlreadySent()

  const getPrimaryBtn = (): ButtonProps => {
    if (currentStep === 1) {
      return {
        type: 'submit',
        title: 'Next',
        // disabled: !firstStepDone,
        hide: isSent
      }
    }

    return {
      type: 'submit',
      title: 'Next'
      // disabled: !firstStepDone
    }
  }

  const isKyc = userStore.user?.flowType === FlowType.KYC

  return (
    <CardScrollable
      title="Financial Information"
      loading={isLoading}
      formMethods={methods}
      formOnSubmit={onPromise(methods.handleSubmit(onSubmit))}
      secondaryBtn={{ onClick: navigateBack }}
      primaryBtn={getPrimaryBtn()}
    >
      {currentStep === 0 && (
        <InvestmentQuestions methods={methods} disabled={isSent} />
      )}
      {currentStep === 1 &&
        (isKyc ? (
          <KycFinancialInformation methods={methods} disabled={isSent} />
        ) : (
          <KybFinancialInformation methods={methods} disabled={isSent} />
        ))}
    </CardScrollable>
  )
}

export default observer(FinancialInformation)
